var encryptRequest = function (queryObj) {
    var secret = 'xIUx&kpX';
    var createTimeStamp = function () {
        return new Date().getTime()
    }
    var objToQuerytStrInSequence = function (obj) {
        if (typeof obj != 'object') {
            return;
        }
        obj.verify = 'regverify';
        var keyArr = Object.keys(obj).sort();
        var rs = [];
        for (var i = 0, l = keyArr.length; i < l; i++) {
            var key = keyArr[i];
            rs.push(key + '=' + obj[key]);
        }
        return rs.join('&');
    }
    var randomString = function (len) {
        len = len || 32;
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var maxPos = $chars.length;
        var pwd = '';
        for (var i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    }
    var timestamp = createTimeStamp();
    var nonce = randomString() + createTimeStamp();
    var md5Str = md5(secret + objToQuerytStrInSequence(queryObj) + nonce) + timestamp;
    var sign = md5(md5Str);
    return {
        sign,
        timestamp,
        nonce
    }
}
